.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
  }

  .modal-content {
    position: relative;
    z-index: 1001;
    background: white;
    padding: 30px;
    border-radius: 12px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
  }

  .input-label {
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
  }

  .prefix {
    background-color: #f5f5f5;
    padding: 10px;
    border-right: 1px solid #ddd;
    font-size: 1rem;
    font-weight: bold;
  }

  .input-field {
    flex: 1;
    padding: 10px;
    border: none;
    font-size: 1rem;
    outline: none;
  }

  .input-field.border-red-500 {
    border: 1px solid red;
  }

  .submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }

  .submit-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .description {
    font-size: 0.9rem;
    color: #666;
    margin-top: 5px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }