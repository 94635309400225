/* PoojaCarousel.css */
.slick-slider {
  margin: 0 auto;
}

.slick-slide img {
  margin: auto;
  display: block;
}

.slick-prev,
.slick-next {
  font-size: 16px; /* ensures text is visible */
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  color: white; /* text color */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  cursor: pointer;
}

.slick-next {
  right: 2px;
}

.slick-prev {
  left: 2px;
}

.slick-dots {
  bottom: -15px !important;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li img {
  max-width: 30px !important; /* or the size you want */
  height: auto;
  cursor: pointer;
  border: 2px solid transparent;
}

.slick-dots li.slick-active img {
  border-color: #cccccc;
}

.slick-next:before {
  content: "";
}
.slick-prev:before {
  content: "";
}

.thumbnail {
  object-fit: contain; /* This will cover the area without stretching the image */
}

@media only screen and (max-width: 768px) {
  /* your styles for smaller screens */
}
