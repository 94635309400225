@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body, html {
  overflow-x: hidden;
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

@font-face {
  font-family: 'Hurricane';
  /* src: url('./path-to-your-hurricane-font.woff2') format('woff2'); */
}


/* @font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./assets/fonts/Poppins-ExtraBold.ttf);
} */

/* .ant-carousel .slick-dots li button {
  background: #2a2929;
  opacity: 0.4;

}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #2a2929;
} */
.gradientCss {
  background: hsla(33, 100%, 53%, 1);

  background: linear-gradient(
    0deg,
    hsla(33, 100%, 53%, 1) 0%,
    hsla(58, 100%, 68%, 1) 100%
  );

  background: -moz-linear-gradient(
    0deg,
    hsla(33, 100%, 53%, 1) 0%,
    hsla(20, 100%, 53%, 1) 30%,
    hsla(58, 100%, 68%, 1) 80%
  );

  background: -webkit-linear-gradient(
    0deg,
    hsla(33, 100%, 53%, 1) 0%,
    hsla(58, 100%, 68%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FF930F", endColorstr="#FFF95B", GradientType=1 );
}

.blurred-content {
  filter: blur(5px);
  background: rgba(255, 255, 255, 0.7);
}


.flash-light-button {
  position: relative;
  overflow: hidden; /* Ensure the animation stays within the button bounds */
}

.flash-light-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%; /* Start outside the left side */
  width: 150%; /* Cover the button with the light animation */
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: flashLight 2s infinite ease-in-out;
}

@keyframes flashLight {
  from {
    left: -150%;
  }
  to {
    left: 150%;
  }
}

.animate-bounce-horizontal {
  animation: bounce-horizontal 1s infinite alternate;
}

@keyframes bounce-horizontal {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(5px); /* Adjust the bounce distance */
  }
}

.markdown-content {
  color: #4a4a4a;
  line-height: 1.6;
  font-size: 12px;
}
.markdown-content a {
  color: #1e90ff;
  text-decoration: underline;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}